import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import Description from "../components/Description/Description";

export const postProductionImage = graphql`
  fragment postProductionImage on File {
    childImageSharp {
      fluid(maxHeight: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "post_production/hero.jpg" }) {
      ...postProductionImage
    }
  }
`;

const PostProduction = ({ data }) => (
  <Layout pageUrl="post-production">
    <SEO title="Post Production" />
    <Hero
      home={false}
      pageTitle="Post Production"
      heroImage={data.heroImage.childImageSharp.fluid}
      heroAlt="Post Production hero"
    />
    <Description
      heading="Una post produzione veloce e mirata"
      description="Siamo in grado di garantire una post produzione rapida ed efficace, calibrata secondo le diverse tipologie di scatti destinate al mondo digital. Il nostro team di professionisti assicura un'elevata velocità di consegna e la più alta uniformità sia su immagini prodotte da Parallel Milano, sia realizzate in autonomia dal brand."
    />
  </Layout>
);

export default PostProduction;
